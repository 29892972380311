import React from 'react';
import "../App.css";
import styled from "styled-components";
import { Link } from "react-router-dom";

import boner_killer from '../static/boner_killer.png'
import battery from '../static/battery.png'
import birlboss from '../static/birlboss.png'
import dino from '../static/dino.png'
import dragonite from '../static/dragonite.png'
import eye_contact from '../static/eye_contact.png'
import heart_cactus from '../static/heart_cactus.png'
import jedi from '../static/jedi.png'
import mac from '../static/mac.png'
import mitty from '../static/mitty.png'
// import professional from '../static/professional.png'
import shark from '../static/shark.png'
import showdown from '../static/showdown.png'
import sofa from '../static/sofa.png'
// import squid from '../static/squid.png'
import bear from '../static/bear.jpeg'
import frog from '../static/frog.jpeg'
import piss from '../static/piss.jpeg'
import rin from '../static/rin.jpeg'
import thumbs_up from '../static/thumbs_up.png'
import uiuc from '../static/uiuc.png'
import duck from '../static/duck.png'
import eclipse from '../static/eclipse.png'
import florida from '../static/florida.png'
import leaf_house from '../static/leaf_house.png'
import bucket from '../static/bucket.png'
import nyc from '../static/nyc.png'
import saranghae from '../static/saranghae.png'
import spine from '../static/spine.png'
import wework from '../static/wework.png'
import butt_stop from '../static/butt_stop.png'
import broccoli from '../static/broccoli.png'
import dj from '../static/dj.png'
import tower from '../static/tower.png'
import sing from '../static/sing.png'
import betty from '../static/betty.png'

// import internet_explorer from '../static/aol_internet_explorer.gif'
// import silicon_valley from '../static/silicon_valley_button.gif'
// import ieisevil from '../static/ieisevil.gif'
// import amazing_free_stuff from '../static/amazing_free_stuff.gif'
// import hello_kitty from '../static/hello_kitty.gif'
// import music from '../static/music.gif'
// import lol from '../static/lol.gif'
// import neo from '../static/neo.gif'
// import death from '../static/death.gif'
// import gba from '../static/gba.gif'
// import www from '../static/www.gif'
// import esheep from '../static/esheep.gif'
// import atari from '../static/atari.gif'
// import set from '../static/set.gif'
// import monitor from '../static/monitor.gif'
// import d3 from '../static/3d.gif'

const StyledNavLink = styled(Link)`
    :hover {
        cursor: wait;
    }
`

const Solo = styled.div`
    background-color: black;
`;

const Row = styled.div`
    justify-content: center;
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
    flex: 25%;
    /* max-width: 25%; */
    div {
        padding: 0px;
        align-items: center;
        margin: 0.75em 0;
    }
    margin: 0.25em 0.5em;
`

function Pictures() {
    return (
      <Solo className="App">
            <div class="row">
                <div class="column">
                    <img src={spine} alt="img"/>
                    <img src={eye_contact} alt="img"/>
                    <img src={boner_killer} alt="img"/>
                    <img src={dino} alt="img"/>
                    <img src={bear} alt="img"/>
                    <img src={mac} alt="img"/>
                    <img src={shark} alt="img"/>
                    <img src={broccoli} alt="img"/>
                    <img src={betty} alt="img"/>
                    
                </div>
                <div class="column">
                    <img src={dj} alt="img"/>
                    <img src={nyc} alt="img"/>
                    <img src={saranghae} alt="img"/>
                    <img src={birlboss} alt="img"/>
                    <img src={leaf_house} alt="img"/>
                    <img src={bucket} alt="img"/>
                    <img src={florida} alt="img"/>
                    <img src={frog} alt="img"/>
                </div>
                <div class="column">
                    <img src={sofa} alt="img"/>
                    <img src={uiuc} alt="img"/>
                    <img src={mitty} alt="img"/>
                    <img src={thumbs_up} alt="img"/>
                    <img src={battery} alt="img"/>
                    <img src={showdown} alt="img"/>
                    <img src={rin} alt="img"/>
                    <img src={tower} alt="img"/>    
                    <img src={dragonite} alt="img"/>    
                    
                    
                </div>
                <div class="column">
                    <img src={sing} alt="img"/>
                    <img src={eclipse} alt="img"/>
                    <img src={duck} alt="img"/>
                    <img src={heart_cactus} alt="img"/>
                    <img src={jedi} alt="img"/>
                    <img src={piss} alt="img"/>
                    <img src={wework} alt="img"/>
                    <img src={butt_stop} alt="img"/>
                    
                    <Row style={{marginTop: '40px'}}>
                        <Column>
                            <StyledNavLink to='/'><u style={{color: 'yellow'}}><p>take me back to ashank.xyz pls</p></u></StyledNavLink>
                        </Column>
                    </Row>
                </div>
                

            </div>
        </Solo>
    );
}

export default Pictures
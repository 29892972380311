import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Pictures from "./pages/Pictures";
import DJ from "./pages/DJ";
import Blog from "./pages/Blog";
import BlogPost from './pages/BlogPost';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  console.log("stop looking")
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/words/:title" element={<BlogPost />} />
          <Route path="/bird" element={<Pictures />} />
          <Route path="/munch" element={<DJ />} />
          <Route path="/words" element={<Blog />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;

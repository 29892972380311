import React from 'react';
import styled from "styled-components"

const Container = styled.div`
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
  font-weight: 100;
  a {
    color: #cccccc;
    text-decoration: none
  }
`

export const SoundCloudPlayer = ({src, title}) => {
    return (
        <>
            <iframe title="kali uchis" width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"
          src={src}/>

        {title && <Container>
            <a class="trackUser" href="https://soundcloud.com/ashank-behara" title="Ashank Behara" target="_blank"
            rel="noreferrer">Ashank Behara</a>&nbsp;-&nbsp;
            <a class="trackTitle" href="https://soundcloud.com/ashank-behara/kali-uchis-told-my-friend-she-wants-me"target="_blank"
            rel="noreferrer">{title}</a>
        </Container>}
        </>
    )
}
import "../App.css";
import styled from "styled-components";
import { Link } from "react-router-dom";

import big_bird from '../static/big_bird.png'
import cookie_monster from '../static/cookie_monster.png'
import elmo from '../static/elmo.png'


import React, { useEffect, useState } from 'react';
import getNowPlayingItem from "../components/Spotify/api";
import { SoundAnimation } from "../components/Spotify/animation";

const SIMPLY_GOOD_MUSIC = 'https://open.spotify.com/playlist/2MPVd5sHs79uqEAr6Znke8'
const LETS_GET_NEGATIVE = 'https://open.spotify.com/playlist/7FAH28g3CrXb7pPXeSkKKy'
const SOUNDCLOUD = 'https://soundcloud.com/ashank-behara/likes'
const INEEDTOFUCKMYEARS = 'https://open.spotify.com/playlist/1rVooPvgTfkGtxngwc4B9b'
const BEDROOM_RAVE = 'https://open.spotify.com/playlist/2zl3J6kV0635xcqlFKBwhd'
const KHUN = 'https://open.spotify.com/playlist/5Zn7lFJfT0l3oS4x6OJsbW'


const TWITTER = 'https://twitter.com/AshankBehara'
const INSTAGRAM = 'https://www.instagram.com/1ashank/'
const LINKEDIN = 'https://www.linkedin.com/in/ashankbehara/'
const READCV = 'https://read.cv/ashank'

const Solo = styled.div`
    @media only screen and (max-width: 768px) {  
    /* Your CSS Code for this device size */    
      margin-left: 10%;
      margin-right: 10%;
      width: 80%;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 768px) {  
    /* Your CSS Code for this device size */    
      margin-left: 25%;
      margin-right: 25%;
      width: 50%;
    }
  // gap: 5%;
  margin-top: 7%;
  text-align: start;
`;

const StyledNavLink = styled(Link)`
    text-decoration: none;
    color: black;
`

const StyledHrefLink = styled.a`
    text-decoration: none;
    color: black;
`

const SpaceGrotesk = styled.div`
  font-family: Open Sans;
  font-size: 13px;
  margin-bottom: 5px;
  /* font-weight: 200; */
  line-height: 18px;

`

const Merriweather = styled.div`
  font-family: Open Sans;
  font-size: 12px;
`

const Rokkitt = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  display: flex;
  position: relative;
  align-items: center;

  div {
    display: inline-block;
  }

  hr {
    width: 100%;
    border-top: 1px solid black;
    margin: 3px 10px;
}  
`

const Orbitron = styled.div`
  font-family: Orbitron;
  font-size: 24px;
  font-weight: 700;
`
const Kharla = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  margin-bottom: 7px;
`

const Table = styled.div`
  display: grid;
  grid-template-columns: 1.25fr 6fr;
  grid-gap: 5px;
`

const Spectral = styled.div`
  font-family: Open Sans;
  font-size: 13px;
  align-items: center;
`

const Nunito = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  display: flex; /* Add this line */
  align-items: center; /* Ensures vertical alignment */
  flex-wrap: wrap; /* Allows items to wrap if needed */
  padding-top: 1px;
`

const Hyperlink = styled.a`
  font-family: Open Sans;
  overflow-wrap: break-word;
  word-break: break-all; 
  /* color: ${({secondary}) => secondary ? 'black' : 'blue'}; */
  color: blue;
  
  :hover {
    cursor: help;
  }
`

const Social = styled.a`
  font-family: Open Sans;
  font-size: 11px;
  margin: 0 1.5rem;
  color: black;
  border: gray 1px solid;
  padding: 2px;
  text-decoration: none;
  :hover {
    cursor: zoom-in;
  }
`

const SocialWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1em;
`

const PlaylistContainer = styled.div`
  div {
    margin-bottom: 0px;
    :last-child {
      margin-bottom: 2px;
    }
    :first-child {
      /* margin-top: 3px; */
    }
  }
`

const Playlist = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
`




function Home() {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      Promise.all([
          getNowPlayingItem(),
      ]).then((results) => {
          setResult(results[0]);
          setLoading(false);
      });
    }, 5000); // 5000 milliseconds = 5 seconds
  
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [loading, result]);

  return (
    <div className="App">
      <Solo>

        <Orbitron>Ashank Behara</Orbitron> <br/>
        <Rokkitt><div>ShapeCI</div><hr/><Merriweather>founder</Merriweather></Rokkitt>
        <Kharla>Accelerating hardware development - <Hyperlink style={{fontSize: '11px'}}href="https://www.shape.ci">www.shape.ci</Hyperlink></Kharla>
        <Rokkitt><div>NVIDIA</div><hr/><Merriweather>deep&nbsp;learning&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>DeepMap: Semantic Segmentation on LiDAR</Kharla>
        <Rokkitt><div>Tesla</div><hr/><Merriweather>manufacturing&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>Cell manufacturing, mostly sw/cv tooling for 4680 cells</Kharla>
        <Rokkitt><div>3M</div><hr/><Merriweather>r&d&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>Computer vision in corporate research lab</Kharla>
        <Rokkitt><div>Raad</div><hr/><Merriweather>robotics&nbsp;intern</Merriweather></Rokkitt>
        <Kharla>Robot arm design and kinematics</Kharla><br/>
        <SpaceGrotesk>I dropped out of mechanical engineering and computer science degrees at the University of Illinois. I'm now and forever on a mission to accelerate hardware development.</SpaceGrotesk><br/>
  
        <Table>
          <Spectral>Music</Spectral>
          <PlaylistContainer>
            <Nunito>I {'<3'} music.&nbsp;<StyledNavLink style={{cursor: 'grabbing'}} to='/munch'> I was learning to dj...<img style={{marginLeft: '3px', marginBottom: '-2.5px'}}src={cookie_monster} height="14px" width="14px" alt='cookie-monster'/></StyledNavLink> {!(loading || !result) && <>&nbsp;I'm listening to&nbsp;<span style={{position: 'relative', top: '2px'}}><SoundAnimation/></span>&nbsp;<Hyperlink secondary style={{fontSize: '12px', color: 'green'}} href={result.songUrl}>{result.title}</Hyperlink>&nbsp;rn.</>}&nbsp;Here is more music i like</Nunito>
            {/* {!loading && <Playlist>∙&nbsp;<SoundAnimation/>&nbsp;<Hyperlink secondary style={{fontSize: '11px', color: 'green'}} href={result.songUrl}>{result.title} by {result.artist}</Hyperlink></Playlist>} */}
            <Playlist>∙&nbsp;<Hyperlink secondary style={{fontSize: '11px'}} href={SIMPLY_GOOD_MUSIC}>Indie + r&b</Hyperlink></Playlist>
            <Playlist>∙&nbsp;<Hyperlink secondary style={{fontSize: '11px'}} href={LETS_GET_NEGATIVE}>Twizzy shxt</Hyperlink></Playlist>
            <Playlist>∙&nbsp;<Hyperlink secondary style={{fontSize: '11px'}} href={SOUNDCLOUD}>Absurd mixes and some leaks</Hyperlink></Playlist>
            <Playlist>∙&nbsp;<Hyperlink secondary style={{fontSize: '11px'}} href={INEEDTOFUCKMYEARS}>Hyper/anti pop</Hyperlink></Playlist>
            <Playlist>∙&nbsp;<Hyperlink secondary style={{fontSize: '11px'}} href={BEDROOM_RAVE}>D&B and house?</Hyperlink></Playlist>
            <Playlist>∙&nbsp;<Hyperlink secondary style={{fontSize: '11px'}} href={KHUN}>Cvnty hyperpop</Hyperlink></Playlist>

          </PlaylistContainer>
          <Spectral>Basketball</Spectral>
          <Nunito>I'm {'<'} alright</Nunito>
          <Spectral>Tennis</Spectral>
          <Nunito>I'm washed</Nunito>
          <Spectral>Thrifting</Spectral>
          <Nunito>I like to spend möney.<StyledNavLink style={{cursor: 'grabbing'}} to='/bird'> I'm the biggest bird<img style={{marginLeft: '3px', marginBottom: "-2.5px"}}src={big_bird} height="14px" width="14px" alt='big-bird'/></StyledNavLink></Nunito>
          <Spectral>Records</Spectral>
          <Nunito>My two favs r Stereolab and Peter Cat, but also spent a pretty penny on new Yeat and Geccs on vinyl</Nunito>
          <Spectral>Pokémon</Spectral>
          <Nunito>I peaked at ~1680 gen 6 showdown. Just broke 1700 on gen 9. No vgc.</Nunito>
          <Spectral>Thinking</Spectral>
          <Nunito>ThoughtsOn {'{things}'} from ThoughtSon<StyledNavLink style={{cursor: 'grabbing'}} to='/words'><img style={{marginLeft: '3px', marginBottom: "-2.5px"}}src={elmo} height="14px" width="14px" alt='elmo'/></StyledNavLink></Nunito>
        </Table><br/>
        {/* <SocialWrapper><Social href={TWITTER}>Twitter</Social><Social href={LINKEDIN}>Linkedin</Social><Social href={INSTAGRAM}>Instagram</Social><Social href={READCV}>Resumé</Social></SocialWrapper> */}
      </Solo>
    </div>
  );
}

export default Home;

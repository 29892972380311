import React from 'react';
import "../App.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';

import gr_dfa from '../static/Recordings/gr-dfa.mp3'
import cc_rs from '../static/Recordings/cc-rs.mp3'
import hii_360 from '../static/Recordings/360-hii.mp3'
import pnm_eay from '../static/Recordings/pnm-eay.mp3'
import vvc_yyy from '../static/Recordings/vvc-yyy.mp3'
import ewmd_lyr from '../static/Recordings/ewmd-lyr.mp3'
import yeat_pump from '../static/Recordings/yeat-pump.mp3'
import { SoundCloudPlayer } from "../components/SoundcloudEmbed";


const KALI_UCHIS_SOURCE = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1491026341&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"

const Solo = styled.div`
    // gap: 5%;
    margin-top: 7%;
    text-align: start;

    @media only screen and (max-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 10%;
        margin-right: 10%;
        max-width: 80%;
    }
    @media only screen and (min-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;

        .nowrap {
            white-space: nowrap;
        }
    }
`;

const StyledNavLink = styled(Link)`
    text-decoration: none;
    color: black;
`

const StyledHrefLink = styled.a`
    text-decoration: none;
    color: black;
`

const SpaceGrotesk = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  margin-bottom: 5px;
  /* font-weight: 200; */
  line-height: 18px;

`

const Merriweather = styled.div`
  font-family: Open Sans;
  font-size: 12px;
`

const Rokkitt = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  display: flex;
  position: relative;
  align-items: center;

  div {
    display: inline-block;
  }

  hr {
    width: 100%;
    border-top: 1px solid black;
    margin: 3px 10px;
}  
`

const Orbitron = styled.div`
  font-family: Orbitron;
  font-size: 24px;
  font-weight: 700;
`
const Kharla = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  margin-bottom: 7px;
`

const Table = styled.div`
  display: grid;
  grid-template-columns: 1.25fr 6fr;
  grid-gap: 5px;
`

const Spectral = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  align-items: center;
`

const Nunito = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  /* align-items: center; */
  padding-top: 1px;
`

const Hyperlink = styled.a`
  font-family: Open Sans;
  /* color: ${({secondary}) => secondary ? 'black' : 'blue'}; */
  color: blue;
  
  :hover {
    cursor: help;
  }
`

const PlaylistContainer = styled.div`
  div {
    margin-bottom: 0px;
    :last-child {
      margin-bottom: 2px;
    }
    :first-child {
      /* margin-top: 3px; */
    }
  }
`

const StyledPlayer = styled(ReactAudioPlayer)`
    width: 100%;
    margin-bottom: 15px;
    margin-top: 7px;
    height: 25px;
`

const BigStyledPlayer = styled(ReactAudioPlayer)`
    width: 100%;
    margin-bottom: 15px;
    margin-top: 12px;
    height: 40px;
`

const NewRockitt = styled.div`
  font-family: Open Sans;
  font-size: 17px;
  display: flex;
  position: relative;
  align-items: center;

  div {
    display: inline-block;
  }

  hr {
    width: 100%;
    border-top: 1px solid black;
    margin: 3px 10px;
}  
`

const NewMerriweather = styled.div`
  font-family: Open Sans;
  font-size: 15px;
`






function DJ() {
  return (
    <div className="App">
      <Solo>
        <Orbitron>My mixes</Orbitron> <br/>

        <NewRockitt><div class="nowrap">kali uchis told my friend she wants me</div><hr/><NewMerriweather>4.9.23</NewMerriweather></NewRockitt>
        <br/>
        <Table>
          <Spectral style={{fontSize: '12px'}}>Tracks</Spectral>
          <PlaylistContainer>
            <Nunito>good lies [outro] (overmono), good lies (overmono), propaganda (salami), expressing what matters (disclosure), leaving you (riovaz), yourlove (logic1000), updown (piri), comme des garcons (rina sawayama), vroom vroom (charli xcx), real spring (skrillex & bladee)</Nunito>
          </PlaylistContainer>
        </Table><br/>
        <SoundCloudPlayer src={KALI_UCHIS_SOURCE}/>
      
        <br/>
        <br/>
        <Table>
          <Spectral>Mixed Ideas</Spectral>
          <PlaylistContainer>
          <Nunito>
            <Rokkitt><div class="nowrap">Yam (Yeat) → Esskeetit (Lil Pump)</div><hr/><Merriweather>11.30.23</Merriweather></Rokkitt>
            <StyledPlayer
                src={yeat_pump}
                controls
            />
            <Rokkitt><div class="nowrap">Guddi Riddim (DJ Snake/Wade/Nooran Sisters) → Delilah (Fred Again)</div><hr/><Merriweather>5.26.23</Merriweather></Rokkitt>
            <StyledPlayer
                src={gr_dfa}
                controls
            />
            {/* <Rokkitt><div class="nowrap">Where Yo Swag (Devstacks/Vonte*) → Wake Up (Dom Corleo)</div><hr/><Merriweather>4.22.23</Merriweather></Rokkitt>
            <StyledPlayer
                src={wys_wu}
                controls
            /> */}
            </Nunito>
            <Nunito>
            <Rokkitt><div class="nowrap">Expressing What Matters (Disclosure) → Leaving You (Riovaz)</div><hr/><Merriweather>4.7.23</Merriweather></Rokkitt>
            <StyledPlayer
                src={ewmd_lyr}
                controls
            />
            </Nunito>
            <Nunito>
            <Rokkitt><div class="nowrap">pain 1993 (ivysprint) → "EA" unreleased (Lil Yachty)</div><hr/><Merriweather>4.7.23</Merriweather></Rokkitt>
              <StyledPlayer
                  src={pnm_eay}
                  controls
              />
            </Nunito>
            <Nunito>
              <Rokkitt><div class="nowrap">Vroom Vroom (Charli XCX) → Ya Ya (Yeat)</div><hr/><Merriweather>4.7.23</Merriweather></Rokkitt>
              <StyledPlayer
                  src={vvc_yyy}
                  controls
              />
            </Nunito>
            <Nunito>
              <Rokkitt><div class="nowrap">360 Baby (Dizzy Fae) → Hot In It (Tiësto/Charli XCX)</div><hr/><Merriweather>3.8.23</Merriweather></Rokkitt>
              <StyledPlayer
                  src={hii_360}
                  controls
              />
            </Nunito>
            <Nunito>
              <Rokkitt><div class="nowrap">MXDEL (CYPARISS/blxty/mental) → Real Spring (Skrillex/Bladee)</div><hr/><Merriweather>3.7.23</Merriweather></Rokkitt>
              <StyledPlayer
                  src={cc_rs}
                  controls
              />
            </Nunito>

          </PlaylistContainer>
        </Table><br/>

        <Table>
          <Spectral>Ideas</Spectral>
          <PlaylistContainer>
            <Nunito>I Feel Fantastic (Riovaz) → Worst In Me (Kaytranada/Tinashe)</Nunito>
            <Nunito>I Need U (Ken Carson) → Systëm (Yeat)</Nunito>
            <Nunito>Pace Yourself (Riovaz) → On & On (Piri and Tommy)</Nunito>
            <Nunito>Break it Off (Pinkpantheress) → On & On (Piri and Tommy)</Nunito>
            <Nunito>Poland (Jordan Ward) → 4tspoon → ttwlg (Fazo)</Nunito>

          </PlaylistContainer>
        </Table><br/>

        <hr/>
<SpaceGrotesk>I started messing with dj stuff on 3/6/23. Super new, but trying 2 figure it out. Pls give me any tips. Hit me on twitter. You can find it on <Hyperlink style={{fontSize: '11px'}}href="https://www.ashank.xyz">www.ashank.xyz</Hyperlink>. </SpaceGrotesk><br/>
      </Solo>
    </div>
  );
}

export default DJ;
